import { Resources } from 'locales/localeType';

import { SUPPORTED_COUNTRY } from './constants';

const SHORT_FORM_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const dateToShortForm = (dt: Date) =>
  `${dt.getDate()} ${SHORT_FORM_MONTHS[dt.getMonth()]}`;

type DateComponents = {
  year: number;
  month: number;
  longMonth: keyof Resources;
  day: number;
  weekDay: number;
};

/**
 * Parses a date string and returns an object containing its components.
 *
 * @example
 * const date = '2021-04-01T06:30:00.000Z';
 * console.log(parseDate(date));
 * // {
 * //   year: 2021,
 * //   month: 3,
 * //   longMonth: 'april',
 * //   day: 1,
 * //   weekDay: 4,
 * // }
 */
export const parseDate = (dateString: string): DateComponents => {
  const date = new Date(dateString);

  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    longMonth: date
      // We hardcode the locale to 'en-GB' because we need the month to be in English
      .toLocaleString('en-GB', { month: 'long' })
      .toLowerCase() as keyof Resources,
    day: date.getDate(),
    weekDay: date.getDay(),
  };
};

export const getDateFromCountryFormat = (
  dateString: string,
  countryCode: Lowercase<SUPPORTED_COUNTRY>
) => {
  switch (countryCode) {
    case 'gb':
      const [day, month, year] = dateString.split('/');
      return new Date(+year, +month - 1, +day);

    default:
      return new Date(dateString);
  }
};
