import {
  InputCheckbox,
  InputSelect,
  InputText,
  LinkText,
  SelectOptions,
  Text,
} from '@global-ecom/nitro-uds/elements';
import { UseFormMethods } from 'react-hook-form';
import React from 'react';

import { ContactInput } from '__generated__/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { AddressLookup } from 'ui/components/AddressLookup';
import { translateSelectOptions } from 'ui/forms/custom/helpers';
import {
  SHIPPING_LOOKUP_CONSTRAINTS as LOOKUP_CONSTRAINTS,
  SUPPORTED_COUNTRY,
} from 'utils/constants';
import { matchStateAndPostalCode } from 'utils/matchStateAndPostalCode';
import { richTranslate } from 'utils/richTranslate';
import { getDateFromCountryFormat } from 'utils/dateFormatter';

const SIXTEEN_YEARS = 60 * 60 * 24 * 365 * 16 * 1000;

export type SignupFormFields = {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  shoppingPreference?: string;
  dob?: string;
  addressCity?: string;
  addressLine?: string;
  addressPostalCode?: string;
  phoneNumber?: string;
  stateCode: string;
  montreal: string;
  toronto: string;
  inverted: boolean;
  emailPreference: string;
  smsOptIn?: boolean;
};

export const SignUpFormField: React.FC<{
  field: string;
  form: UseFormMethods<SignupFormFields>;
  isLoading: boolean;
  inverted?: boolean;
  details: { ctaLabel: string };
}> = ({ field, form, isLoading, inverted, details }) => {
  const t = useTranslate();
  const [validate] = useValidation();
  const {
    countryCode,
    env: { googleApiKey },
    newsletter,
  } = useSiteConfig();

  switch (field) {
    case 'address':
      return (
        <AddressLookup
          invert={inverted}
          name={'addressLine'}
          defaultValue={form.watch('addressLine')}
          id="address-line"
          data-test-id="sign-up-address-lookup"
          label={t('address1')}
          placeholder={t('address1')}
          lookupConstraints={LOOKUP_CONSTRAINTS[countryCode]}
          onSelect={({ postalCode, address1, city }) => {
            if (postalCode && address1 && city) {
              form.setValue(`addressCity`, city);
              form.setValue(`addressPostalCode`, postalCode);
              form.setValue(`addressLine`, address1);
            }
          }}
          ref={form.register({
            required: t('requiredField'),
            maxLength: {
              value: 50,
              message: t('genericFieldMaxLength', {
                length: 50,
              }),
            },
          })}
          maxLength={50}
          required
          errorText={form.errors.addressLine?.message}
          disabled={isLoading}
        />
      );
    case 'city':
      return (
        <InputText
          invert={inverted}
          id="addressCity"
          name="addressCity"
          label={t('city')}
          placeholder={t('city')}
          ref={form.register({
            validate: validate.safeInput,
            required: t('requiredField'),
            minLength: {
              value: 2,
              message: t('genericFieldMinLength', {
                length: 2,
              }),
            },
            maxLength: {
              value: 50,
              message: t('genericFieldMaxLength', {
                length: 50,
              }),
            },
          })}
          disabled={isLoading}
          minLength={2}
          maxLength={50}
          errorText={form.errors.addressCity?.message}
          required
          crossOrigin={undefined}
        />
      );
    case 'zip-code':
      return (
        <InputText
          invert={inverted}
          id="addressPostalCode"
          name="addressPostalCode"
          label={t('postalCode')}
          placeholder={t('postalCode')}
          ref={form.register({
            required: t('requiredField'),
            minLength: {
              value: 5,
              message: t('genericFieldMinLength', {
                length: 5,
              }),
            },
            maxLength: {
              value: 10,
              message: t('genericFieldMaxLength', {
                length: 10,
              }),
            },
            validate: value => {
              if (!value) return;
              const errorMessage = validate.postalCode(value) as string;
              return errorMessage;
            },
          })}
          disabled={isLoading}
          minLength={5}
          maxLength={10}
          errorText={form.errors.addressPostalCode?.message}
          required
          crossOrigin={undefined}
        />
      );
    case 'email':
      return (
        <InputText
          id="email"
          invert={inverted}
          className="w-full"
          name="email"
          type="email"
          label={t('email')}
          placeholder={t('email')}
          disabled={isLoading}
          required
          ref={form.register({
            required: t('requiredField'),
            validate: validate.email,
            maxLength: {
              value: 50,
              message: t('yourEmailCannotBeLong', {
                length: 50,
              }),
            },
          })}
          maxLength={50}
          errorText={form.errors.email?.message}
          crossOrigin={undefined}
        />
      );
    case 'dob':
      const validateDob = value => {
        const invalidDOB = validate.dateOfBirth(value);

        if (invalidDOB) return invalidDOB;

        const currentTimeStamp = new Date().getTime();

        const birthdateTimestamp = getDateFromCountryFormat(
          value,
          countryCode
        ).getTime();

        if (isNaN(birthdateTimestamp)) {
          return t('newsletterPopoverDobInvalid');
        }

        const targetTimeStamp = currentTimeStamp - SIXTEEN_YEARS;

        if (birthdateTimestamp > targetTimeStamp) {
          return t('newsletterPopoverAgeRestriction');
        }

        return undefined;
      };

      return (
        <InputText
          id="dob"
          name="dob"
          invert={inverted}
          label={t('newsletterPopoverDOBPlaceholder')}
          placeholder={t('newsletterPopoverDOBPlaceholder')}
          maxLength={10}
          disabled={isLoading}
          required
          ref={form.register({
            required: t('newsletterPopoverDobRequired'),
            validate: validateDob,
          })}
          errorText={form.errors.dob?.message}
          crossOrigin={undefined}
        />
      );
    case 'interests':
      return (
        <InputSelect
          invert={inverted}
          disabled={isLoading}
          name="shoppingPreference"
          options={[
            { label: t('newsletterMens'), value: 'mens' },
            { label: t('newsletterWomens'), value: 'womens' },
            { label: t('newsletterKids'), value: 'kids' },
            { label: t('newsletterAll'), value: 'all' },
          ]}
          placeholder={t('newsletterPopoverChoosePreference')}
          defaultValue={form.watch('shoppingPreference')}
          id="shoppingPreference"
          dataTestId="sign-up-interests"
          label={t('newsletterPopoverShoppingPreference')}
          ref={form.register({
            required: t('newsletterPopoverShoppingPreferenceError'),
          })}
          errorText={
            form.errors.shoppingPreference &&
            form.errors.shoppingPreference.message
          }
        />
      );
    case 'phone':
      return (
        <InputText
          invert={inverted}
          disabled={isLoading}
          ref={form.register({
            required: t('requiredField'),
            validate: validate.phoneNumber,
            maxLength: {
              value: 30,
              message: t('genericFieldMaxLength', {
                length: 30,
              }),
            },
          })}
          name="phoneNumber"
          id="phone-number"
          type="tel"
          errorText={form.errors.phoneNumber?.message}
          maxLength={30}
          label={t('phone')}
          placeholder={t('phone')}
          required
          crossOrigin={undefined}
        />
      );
    case 'firstName':
    case 'lastName':
      const { id, placeholder } =
        field === 'firstName'
          ? { id: 'first-name', placeholder: t(field) }
          : { id: 'last-name', placeholder: t(field) };
      return (
        <InputText
          invert={inverted}
          disabled={isLoading}
          ref={form.register({
            required: t('requiredField'),
            validate: validate.safeInput,
          })}
          name={field}
          id={id}
          label={placeholder}
          placeholder={placeholder}
          errorText={form.errors[field]?.message}
          required
          crossOrigin={undefined}
        />
      );
    case 'opt-out':
      return (
        <InputCheckbox
          invert={inverted}
          ref={form.register({
            required: t('requiredField'),
          })}
          name={field}
          id={field}
          readOnly
        >
          {field.toUpperCase()}
        </InputCheckbox>
      );
    case 'ageCheck':
      return (
        <InputCheckbox
          invert={inverted}
          ref={form.register({
            required: t('requiredField'),
          })}
          name={field}
          id={field}
          required
        >
          {t('legalAgeCheck')}
        </InputCheckbox>
      );
    case 'montreal':
    case 'toronto':
      return (
        <InputCheckbox
          invert={inverted}
          ref={form.register({
            validate: () =>
              form.watch('toronto') ||
              form.watch('montreal') ||
              'Select at least one city',
          })}
          name={field}
          id={field}
        >
          {field.toUpperCase()}
        </InputCheckbox>
      );
    case 'california':
      return (
        <InputSelect
          invert={inverted}
          id="address-form-state-select"
          dataTestId="address-form-state-select-california"
          label={t('state')}
          placeholder={t('selectState')}
          defaultValue={'CA'}
          options={[{ label: 'California', value: 'CA' }]}
          className="pt-0.5 pb-1"
          name="stateCode"
          required
          ref={form.register({
            required: t('requiredField'),
            validate: {
              postalStateMatch: async value => {
                if (value !== 'CA') return 'California residents only';
                const postalCode = form.getValues('addressPostalCode');
                if (!postalCode || form.errors.addressPostalCode)
                  return undefined;

                const { isMatch } = await matchStateAndPostalCode({
                  postalCode: postalCode as string,
                  stateCode: value ? (value as string) : null,
                  country: 'US',
                  googleApiKey,
                });
                if (!isMatch) return t('stateInvalidPostcode');
              },
            },
          })}
          errorText={form.errors.stateCode?.message}
        />
      );
    case 'gender':
      const genderValues = newsletter?.fields?.gender?.values || [
        'male',
        'female',
        'noAnswer',
      ];
      const genderRequired = newsletter?.fields?.gender?.required;
      const genderSelectItems: SelectOptions = genderValues.map(value => ({
        value,
        label: value,
      }));

      const genderFieldsTranslated = genderSelectItems
        ? translateSelectOptions(genderSelectItems, t)
        : [];

      return (
        <InputSelect
          invert={inverted}
          dataTestId="sign-up-gender-select"
          disabled={isLoading}
          name="gender"
          options={genderFieldsTranslated}
          placeholder={t('gender')}
          id="gender"
          label={t('newsletterChoseGender')}
          ref={form.register({
            required: t('requiredField'),
          })}
          required={genderRequired}
          errorText={form.errors.gender && form.errors.gender.message}
        />
      );
    case 'emailPreference':
      return (
        <div className="flex justify-start text-left">
          <InputCheckbox
            name={field}
            id={field}
            invert={inverted}
            ref={form.register()}
          >
            {t('newsletterPopoverBeTheFirst')}
          </InputCheckbox>
        </div>
      );
    case 'sms-opt-in':
      const smsOptInValue = form.watch('smsOptIn');
      return (
        <>
          <InputText
            invert={inverted}
            disabled={isLoading}
            optional
            ref={form.register({
              validate: {
                phoneNumber: value => !value || validate.phoneNumber(value),
                smsOptIn: phoneNumber => {
                  const { smsOptIn } = form.getValues();

                  // The checkbox and phone number must be submitted together, or not at all:
                  if (phoneNumber && !smsOptIn) {
                    return t('smsOptInMustBeChecked');
                  }
                  if (!phoneNumber && smsOptIn) {
                    return t('requiredField');
                  }
                  return true;
                },
              },
              maxLength: {
                value: 30,
                message: t('genericFieldMaxLength', {
                  length: 30,
                }),
              },
            })}
            name="phoneNumber"
            id="phone-number"
            type="tel"
            errorText={form.errors.phoneNumber?.message}
            maxLength={30}
            label={t('smsOptInPhoneNumberLabel')}
            placeholder={t('smsOptInPhoneNumberLabel')}
            crossOrigin={undefined}
          />
          <InputCheckbox
            className="mt-4"
            name="smsOptIn"
            id="smsOptIn"
            invert={inverted}
            ref={form.register({
              validate: {
                fields: smsOptIn => {
                  const { email, firstName, lastName, phoneNumber } =
                    form.getValues();

                  if (firstName === '' && smsOptIn) {
                    form.setError('firstName', {
                      type: 'required',
                      message: t('requiredField'),
                    });
                  }
                  if (lastName === '' && smsOptIn) {
                    form.setError('lastName', {
                      type: 'required',
                      message: t('requiredField'),
                    });
                  }
                  if (email === '' && smsOptIn) {
                    form.setError('email', {
                      type: 'required',
                      message: t('requiredField'),
                    });
                  }
                  if (phoneNumber === '' && smsOptIn) {
                    form.setError('phoneNumber', {
                      type: 'required',
                      message: t('requiredField'),
                    });
                  }

                  return true;
                },
              },
            })}
            onChange={() => {
              // Clear the error if the checkbox is selected and the error message matches the expected error.
              // Note: The `deps` register option would be a better way to do this, but it's not available in v6.
              if (
                form.errors.phoneNumber?.message === t('smsOptInMustBeChecked')
              ) {
                form.clearErrors('phoneNumber');
              }
              if (form.errors.firstName?.message === t('requiredField')) {
                form.clearErrors('firstName');
              }
              if (form.errors.lastName?.message === t('requiredField')) {
                form.clearErrors('lastName');
              }
              if (form.errors.email?.message === t('requiredField')) {
                form.clearErrors('email');
              }
              if (form.errors.phoneNumber?.message === t('requiredField')) {
                form.clearErrors('phoneNumber');
              }

              form.trigger();
            }}
          >
            {t('smsOptInLabel')}
          </InputCheckbox>

          <>
            {smsOptInValue && (
              <>
                <Text size="xs" className="block mt-4">
                  {t('smsOptInTermsAndConditions', {
                    ctaLabel: details.ctaLabel,
                  })}
                </Text>
                <Text size="xs" className="block">
                  {richTranslate(t, 'smsOptInTermsAndConditionsView', {
                    termsLink: text => (
                      <LinkText
                        label={text}
                        target="_blank"
                        href="/help/terms-and-conditions"
                      />
                    ),
                    privacyLink: text => (
                      <LinkText
                        label={text}
                        target="_blank"
                        href="/help/privacy-policy"
                      />
                    ),
                  })}
                </Text>
              </>
            )}
          </>
        </>
      );
    default:
      return null;
  }
};

export const createContactInput = (
  data: SignupFormFields,
  contactSource: string,
  countryCode: Lowercase<SUPPORTED_COUNTRY>
): ContactInput => {
  const contact: ContactInput = {
    email: data.email,
    emailPreference: 'optIn',
    contactSource,
  };
  if (data.emailPreference !== undefined) {
    contact.emailPreference = data.emailPreference ? 'optIn' : 'optOut';
  }
  if (data.dob) {
    const date = getDateFromCountryFormat(data.dob, countryCode);

    contact.dob = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  if (data.firstName && data.lastName) {
    contact.firstName = data.firstName;
    contact.lastName = data.lastName;
  }

  if (data.gender) {
    contact.gender = data.gender;
  }

  if (data.shoppingPreference) {
    contact.shoppingPreference = data.shoppingPreference;
  }

  if (data.phoneNumber) {
    contact.phoneNumber = data.phoneNumber;
  }

  if (data.addressLine && data.addressCity && data.addressPostalCode) {
    contact.address = data.addressLine;
    contact.postalCode = data.addressPostalCode;
    contact.city = data.addressCity;
  }

  if (data.toronto) {
    contact.toronto = true;
  }
  if (data.montreal) {
    contact.montreal = true;
  }

  if (data.smsOptIn !== undefined) {
    contact.smsOptIn = data.smsOptIn;
  }

  return contact;
};
